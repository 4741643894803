/* global angular */

/** @ngInject */
function StockServiceCall (
  $rootScope,
  $scope,
  $mdDialog,
  $stateParams,
  Stock,
  $translate,
  ResolvedAvailableWarehouses,
  Warehouse,
  PartAssembly,
  $localStorage
) {
  const vm = this
  $scope.inProgress = false

  let selectedAssemblyWarehouse = null
  let selectedDisassemblyWarehouse = null

  // Load last selected warehouses from $localStorage if available
  const lastAssemblyWarehouseId = $localStorage.lastAssemblyWarehouseId
  const lastDisassemblyWarehouseId = $localStorage.lastDisassemblyWarehouseId

  $scope.actions = [
    {
      name: 'הרכבה',
      value: 'add'
    },
    {
      name: 'החלפה (הרכבה + פירוק)',
      value: 'replace'
    },
    {
      name: 'פירוק',
      value: 'remove'
    }
  ]

  vm.formWizard = $scope.formWizard = {}

  $scope.headerOptions = {
    icon: 'icon-cog',
    template: require('app/templates/headers/simple.html'),
    title: 'החלפת מכשירים במכשיר אב',
    fabButton: {}
  }

  $scope.msWizard = {
    selectedIndex: 0
  }

  $scope.sendForm = async wizardData => {
    $scope.inProgress = $rootScope.loadingProgress = true
    let res
    let partErrors = null

    // Set warehouse data based on action type
    if (wizardData.action === 'add') {
      // For assembly only, use assembly warehouse
      wizardData.assemblyWarehouseName = selectedAssemblyWarehouse.name
      wizardData.assemblyWarehouseLocation = selectedAssemblyWarehouse.location
      wizardData.disassemblyWarehouseName = null
      wizardData.disassemblyWarehouseLocation = null
    } else if (wizardData.action === 'remove') {
      // For disassembly only, use disassembly warehouse
      wizardData.assemblyWarehouseName = null
      wizardData.assemblyWarehouseLocation = null
      wizardData.disassemblyWarehouseName = selectedDisassemblyWarehouse.name
      wizardData.disassemblyWarehouseLocation =
        selectedDisassemblyWarehouse.location
    } else {
      // For replace (both assembly and disassembly), use both warehouses
      wizardData.assemblyWarehouseName = selectedAssemblyWarehouse.name
      wizardData.assemblyWarehouseLocation = selectedAssemblyWarehouse.location
      wizardData.disassemblyWarehouseName = selectedDisassemblyWarehouse.name
      wizardData.disassemblyWarehouseLocation =
        selectedDisassemblyWarehouse.location
    }
    const data = angular.copy(wizardData)
    delete data.assemblyWarehouse
    delete data.disassemblyWarehouse
    try {
      const { serviceCall, errors } = await Stock.serviceCall(data).$promise
      res = serviceCall
      partErrors = errors
      console.log('res', res)
      $scope.wizardModel.wizard = {}
      $scope.selectedIndex = 0
    } catch (err) {
      res = err.data.error
    }
    $mdDialog.show({
      /** @ngInject */
      controller: ($scope, $mdDialog, res) => {
        $scope.res = res
        $scope.partErrors = partErrors
        $scope.header = 'תוצאות ממשק'
        $scope.cancel = () => $mdDialog.cancel()
      },
      multiple: true,
      template: require('./stock.service.call.dialog.html'),
      parent: angular.element(document.body),
      targetEvent: '',
      locals: {
        res
      },
      clickOutsideToClose: true
    })
    $scope.inProgress = $rootScope.loadingProgress = false
  }

  $scope.wizardModel = {
    wizard: {},
    assemblyWarehouse: lastAssemblyWarehouseId,
    disassemblyWarehouse: lastDisassemblyWarehouseId
  }

  if ($stateParams.parent) {
    const { sku, serial } = $stateParams.parent
    $scope.wizardModel.wizard.parentSku = sku
    $scope.wizardModel.wizard.parentSerial = serial
  }

  $scope.wizardFields = [
    {
      key: 'wizard',
      type: 'wizardGroup',
      templateOptions: {
        class: '',
        selectedIndex: $scope.selectedIndex,
        nextButtonText: $translate.instant('WO.WIZARD.NEXT'),
        backButtonText: $translate.instant('WO.WIZARD.BACK'),
        lastStageButtonText: $translate.instant('WO.WIZARD.SEND'),
        busy: $scope.inProgress,
        callback: $scope.sendForm,
        fields: [
          // stage 1
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.SELECT_ACTION'),
              fieldGroup: [
                {
                  key: 'action',
                  type: 'radio',
                  className: 'layout-row',
                  templateOptions: {
                    type: 'text',
                    required: true,
                    options: $scope.actions
                  }
                }
              ]
            }
          },
          // stage 2
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.MASTER_DEVICE_DETAILS'),
              fieldGroup: [
                {
                  key: 'parentSku',
                  type: 'autocomplete',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.WIZARD.MASTER_SKU'),
                    queryMethod: PartAssembly.find,
                    mapObject: {
                      id: 'number',
                      name: 'number'
                    },
                    baseFilterObject: {
                      where: {},
                      fields: {
                        number: true,
                        name: true
                      }
                    }
                  }
                },
                {
                  key: 'parentSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant('WO.WIZARD.DEVICE_SERIAL')
                  }
                }
              ]
            }
          },
          // stage 3
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.PRODUCT_DETAILS'),
              fieldGroup: [
                {
                  key: 'childrenSku',
                  type: 'autocomplete',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant('WO.WIZARD.SUB_DEVICE_SKU'),
                    queryMethod: PartAssembly.find,
                    mapObject: {
                      id: 'number',
                      name: 'number'
                    },
                    baseFilterObject: {
                      where: {},
                      fields: {
                        number: true,
                        name: true
                      }
                    }
                  }
                },
                {
                  key: 'removeChildrenSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant(
                      'WO.WIZARD.DISASSEMBLY_DEVICE_NUMBER'
                    )
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'add'
                  }
                },
                {
                  key: 'addChildrenSerial',
                  type: 'input',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    type: 'text',
                    label: $translate.instant(
                      'WO.WIZARD.ASSEMBLY_DEVICE_NUMBER'
                    )
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'remove'
                  }
                }
              ]
            }
          },
          // stage 4 - Warehouses
          {
            wrapper: 'wizardItem',
            templateOptions: {
              label: $translate.instant('WO.WIZARD.SELECT_WAREHOUSE'),
              fieldGroup: [
                // Assembly warehouse
                {
                  key: 'assemblyWarehouse',
                  type: 'modelSelect',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant(
                      'WO.WIZARD.SELECT_ASSEMBLY_WAREHOUSE'
                    ),
                    findMethod: Warehouse.find,
                    mapObject: {
                      id: 'id',
                      name: 'name',
                      displayName: function (item) {
                        return (
                          item.originalModel.description +
                          (item.originalModel.location
                            ? ` (${item.originalModel.location})`
                            : '')
                        )
                      }
                    },
                    baseFilterObject: {
                      where: {
                        id: {
                          inq: ResolvedAvailableWarehouses
                        }
                      },
                      fields: {
                        id: true,
                        name: true,
                        description: true,
                        location: true
                      }
                    },
                    onChange: function (value, options, model, selectedOption) {
                      selectedAssemblyWarehouse = value
                      if (value) {
                        $localStorage.lastAssemblyWarehouseId = value
                      }
                    }
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'remove'
                  }
                },
                // Disassembly warehouse
                {
                  key: 'disassemblyWarehouse',
                  type: 'modelSelect',
                  className: 'layout-row',
                  templateOptions: {
                    required: true,
                    label: $translate.instant(
                      'WO.WIZARD.SELECT_DISASSEMBLY_WAREHOUSE'
                    ),
                    findMethod: Warehouse.find,
                    mapObject: {
                      id: 'id',
                      name: 'name',
                      displayName: function (item) {
                        return (
                          item.originalModel.description +
                          (item.originalModel.location
                            ? ` (${item.originalModel.location})`
                            : '')
                        )
                      }
                    },
                    baseFilterObject: {
                      where: {
                        id: {
                          inq: ResolvedAvailableWarehouses
                        }
                      },
                      fields: {
                        id: true,
                        name: true,
                        description: true,
                        location: true
                      }
                    },
                    onChange: function (value, options, model, selectedOption) {
                      selectedDisassemblyWarehouse = value
                      // Save to $localStorage
                      if (value) {
                        $localStorage.lastDisassemblyWarehouseId = value
                      }
                    }
                  },
                  hideExpression: function ($viewValue, $modelValue, scope) {
                    return scope.model.action === 'add'
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]

  // Initialize warehouses from $localStorage if available
  if (lastAssemblyWarehouseId) {
    Warehouse.findById({ id: lastAssemblyWarehouseId })
      .$promise.then(warehouse => {
        if (warehouse) {
          selectedAssemblyWarehouse = {
            id: warehouse.id,
            name: warehouse.name,
            location: warehouse.location,
            originalModel: warehouse
          }
          $scope.wizardModel.wizard.assemblyWarehouse = warehouse.id
        }
      })
      .catch(err =>
        console.error('Error loading last assembly warehouse:', err)
      )
  }

  if (lastDisassemblyWarehouseId) {
    Warehouse.findById({ id: lastDisassemblyWarehouseId })
      .$promise.then(warehouse => {
        if (warehouse) {
          selectedDisassemblyWarehouse = {
            id: warehouse.id,
            name: warehouse.name,
            location: warehouse.location,
            originalModel: warehouse
          }
          $scope.wizardModel.wizard.disassemblyWarehouse = warehouse.id
        }
      })
      .catch(err =>
        console.error('Error loading last disassembly warehouse:', err)
      )
  }
}

module.exports = StockServiceCall
